class ZIDXAccountListingIndex implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountListingIndexContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/listing-index";
    }
    render(){
        // console.log("listing index");
    }
}